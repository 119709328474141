<template>
  <v-form
      ref="form"
      v-model="valid"
      lazy-validation
  >
    <v-container>
      <div class="mt-3 mb-5">
        <h3>{{isNew ? 'ユーザ/連絡先 登録' : 'ユーザ/連絡先 編集'}}</h3>
      </div>

      <v-row v-if="company">
        <v-col cols="6">
          <v-text-field label="会社"
                :value="company.company_name"
                dense
                :loading="loading"
                readonly
          ></v-text-field>
        </v-col>
        <v-col cols="6">
          <v-select label="事業所"
              v-model="userData.member_no"
              :items="memberList"
              item-text="officeName"
              item-value="memberNo"
              dense
              @change="memberChange()"
              :loading="loading"
          ></v-select>
        </v-col>
      </v-row>

      <v-row v-else-if="association && association.association_id == '000000'">
        <v-col cols="6">
          <v-text-field label="協会"
                :value="association.association_name"
                dense
                :loading="loading"
                readonly
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row v-else-if="association">
        <v-col cols="6">
          <v-select
                label="日冷倉協"
                v-model="userData.association_id"
                :items="associationList"
                item-text="associationName"
                item-value="associationId"
                dense
                :readonly="isDelete || association.association_id == userData.association_id"
                :loading="loading"
          ></v-select>
        </v-col>
        <v-col cols="6">
          <v-select
                label="冷事協会"
                v-model="userData.frozen_association_id"
                :items="frozenAssociationList"
                item-text="associationName"
                item-value="associationId"
                dense
                :readonly="isDelete || association.association_id == userData.frozen_association_id"
                :loading="loading"
          ></v-select>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="8">
          <v-text-field label="ユーザ名/氏名"
                v-model="userData.name"
                :rules="[required]"
                :maxlength="100"
                :counter="100"
                dense
                :loading="loading"
                :readonly="isDelete"
          ></v-text-field>
        </v-col>
        <v-col cols="4">
          <v-text-field label="担当部門"
                v-model="userData.department"
                :maxlength="20"
                :counter="20"
                dense
                :loading="loading"
                :readonly="isDelete"
          ></v-text-field></v-col>
      </v-row>
      <v-row>
        <v-col cols="8">
          <v-text-field label="ログイン/宛先メールアドレス"
                v-model="userData.email"
                :maxlength="100"
                :counter="100"
                :rules="[required, validEmail]"
                :readonly="!isNew || isDelete"
                dense
                :loading="loading"
          ></v-text-field>
        </v-col>
        <v-col cols="4">
          <v-checkbox label="ログイン"
                v-model="userData.login_flag"
                :true-value="1"
                :false-value="0"
                dense
                :readonly="isDelete"
                class="ml-3"
          ></v-checkbox>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="8">
          <v-text-field
                label="パスワード"
                v-model="userData.password"
                type='password'
                :rules="password_rule.rules"
                :maxlength="20"
                :counter="20"
                :disabled="!(userData.login_flag == 1)"
                :readonly="isDelete"
                dense
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" class="pb-0">
          <v-text-field label="宛先"
                v-model="userData.to"
                dense
                :maxlength="200"
                :counter="200"
                :loading="loading"
                :readonly="isDelete"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" class="pt-0">
          <div class="mt-1">
            <!-- {{userData.to_address}} -->
            {{to_address}}
          </div>
        </v-col>
      </v-row>
      <v-row v-if="isMember" >
        <!-- <v-col class="mt-3">
          <v-checkbox label="メール送信対象"
                v-model="check_membershipfee"
                :true-value="1"
                :false-value="0"
                dense
                :readonly="isDelete"
                @click="checkChange()"
                class="mr-5"
          ></v-checkbox>
        </v-col> -->
        <v-col class="mt-3">
          メール送信対象
        </v-col>
      </v-row>
      <v-row v-if="isMember" >
      <!-- <v-row v-if="false" > -->
        <v-col class="pt-0 mt-0 ml-5 d-flex align-center" cols="12">
          <!-- <v-checkbox label="実態調査"
                v-model="check_factfinding"
                :true-value="1"
                :false-value="0"
                dense
                :readonly="isDelete"
                @click="checkChange()"
                class="mr-5"
          ></v-checkbox> -->
          <v-checkbox label="本社"
                v-model="check_main_office"
                :true-value="1"
                :false-value="0"
                dense
                :readonly="isDelete"
                @click="checkChange()"
                class="mr-5"
          ></v-checkbox>
          <v-checkbox label="事業所"
                v-model="check_member"
                :true-value="1"
                :false-value="0"
                dense
                :readonly="isDelete"
                @click="checkChange()"
                class="mr-5"
          ></v-checkbox>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="6" class="text-center">
          <v-btn v-if="mode!='delete'"
                class="btn_font primary"
                width="120"
                :disabled="loading"
                v-on:click="registData()"
          >保存</v-btn>
          <v-btn v-else
                class="btn_font secondary"
                width="120"
                :disabled="loading"
                v-on:click="deleteData()"
          >削除</v-btn>
        </v-col>
        <v-col cols="6" class="text-center">
          <v-btn class="btn_font primary"
                width="120"
                :disabled="loading"
                v-on:click="close()"
          >キャンセル</v-btn>
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>


<script>
import User from "../model/m_user";
import Member from "../model/member";
import Association from "../model/m_association";

import Message from "../Lib/Message";
import Debug from "../Lib/Debug";

export default {
  props: [
    "mode",
    "editEmail",
    "company",
    "association",
    "memberNo",
    "attribute",
  ],

  data: () => ({
    valid: true,
    loading: false,

    userData: {
      department: "",
      name: "",
      email: "",
      password: "",
      to: "",
      toAddress: "",
      login_flag: 0,
      member_no: "000000",
      association_id: "",
      frozen_association_id: "",

      attribute: 0,

      check_main_office: 0,
      check_member: 0,
    },

    mailCheckList: {
      "000000":
        {
          check_main_office: 0,
          check_member: 0,
          data_save: 0,
        },
    },

    check_main_office: 0,
    check_member: 0,

    memberList: [],
    associationList: [],
    frozenAssociationList: [],

  }),

  // --------------------------------------------------------------------------
  // ライフサイクルフック
  created: function () {
  },

  mounted: async function () {
    Debug.log("UserEdit_vue[mounted]");
    Debug.log("mode: " + this.mode)
    Debug.log("Email: " + this.editEmail)
    Debug.log2("Company: ", this.company)
    Debug.log2("Association: ", this.association)
    Debug.log2("MemberNo: ", this.memberNo)

    this.loadData();
  },

  // --------------------------------------------------------------------------
  // メソッド
  methods: {
    async loadData() {
      Debug.log("function[loadData]");

      // 事業所リストを取得
      if (this.isMember) {
        // 事業所の場合
        await this.getCompanyData();
      } else {
        // 協会の場合
        await this.getAssociationData();
      }

      // 新規の場合、mailCheckList の初期値をセット
      if (this.mode == "new") {

        if (this.isMember) {
          this.mailCheckList = {};
          this.memberList.forEach(m => {
            let mdata = {
              check_main_office: 0,
              check_member: 0,
              data_save: 1,
            };

            if (this.memberList.length > 0) {
              this.mailCheckList[m.memberNo] = mdata;
              this.check_main_office = this.mailCheckList[m.memberNo].check_main_office;
              this.check_member = this.mailCheckList[m.memberNo].check_member;
            }
          });

          this.userData.login_flag = 0;
          if ((this.memberList != null) && (this.memberList.length > 0)) {
            this.userData.member_no = this.memberList[0].memberNo;
            this.userData.association_id = "";
            this.userData.attribute = 0;
          } else {
            this.userData.member_no = "";
            this.userData.association_id = "";
          }
          this.userData.mailCheckList = this.mailCheckList;
          this.userData.attribute = 0;
        } else {
          const association_id = this.association.association_id;

          if (association_id == "000000") {
            this.$set(this.userData, "attribute", 2);
            this.$set(this.userData, "association_id", association_id);
          } else if (association_id < "000500") {
            this.$set(this.userData, "attribute", 1);
            this.$set(this.userData, "association_id", association_id);
            this.$set(this.userData, "frozen_association_id", "");
          } else {
            this.$set(this.userData, "attribute", 1);
            this.$set(this.userData, "frozen_association_id", association_id);
            this.$set(this.userData, "association_id", "");
          }

          this.$set(this.userData, "login_flag", 0);
          this.$set(this.userData, "member_no", "");

          Debug.log2("UserData(association)", this.userData);
        }

        return;
      } else {
        //新規ではない場合
        this.loading = true;

        Debug.log2("attribute->", this.attribute);

        User.getData(this.editEmail, this.attribute)
          .then(res => {
            Debug.log2("Get res.data->", res.data);

            if (res.data) {
              // mailCheckList以外は同じなので 0番目を読み込む
              this.userData = res.data[0];

              // パスワードは空にする
              this.userData.password = '';

              // 事業所の場合のみ
              if (this.isMember) {
                // チェックボックスのデータを作成
                this.mailCheckList = {};
                this.memberList.forEach(m => {
                  const ud = res.data.find((data) => {
                    return data.member_no == m.memberNo;
                  });
                  // mdata を作成
                  let mdata = {
                    check_main_office: 0,
                    check_member: 0,
                    data_save: 0,
                  };

                  // 読み込んだデータがあれば、それで上書き
                  if (ud != null) {
                    mdata.check_main_office = ud.check_main_office;
                    mdata.check_member = ud.check_member;
                    mdata.data_save = 0;
                  }

                  // mailCheckList に保存
                  this.mailCheckList[m.memberNo] = mdata;
                });

                // mailCheckList を userData に保存
                this.userData.mailCheckList = this.mailCheckList;
                this.userData.member_no = this.memberNo;

                // 画面表示データをセット
                this.check_main_office = this.userData.mailCheckList[this.memberNo].check_main_office;
                this.check_member = this.userData.mailCheckList[this.memberNo].check_member;
              }
            }
          })
          .catch((err) => {
            Message.err(err, '情報を取得できませんでした。[ユーザデータ]');
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },

    async getCompanyData() {
      Debug.log("function[getCompanyData]");
      this.loading = true;

      // 会員シートのみ
      const searchCondition = { companyId: this.company.company_id, listType: 0 };
      //const searchCondition = { companyId: this.company.company_id };
      await Member.search(searchCondition)
        .then(res => {
          if (res.data) {
            this.memberList = [];
            res.data.forEach(m => {
              this.memberList.push({"memberNo": m.memberNo, "officeName": m.officeName});
            });
            this.memberList = this.memberList.sort((a, b) => a.officeName.localeCompare(b.officeName));

            Debug.log2("searchCondition->", searchCondition);
            Debug.log2("memberList->", this.memberList);
          }
        })
        .catch((err) => {
          Message.err(err, '情報を取得できませんでした。[事業所データ]');
        })
        .finally(() => {
          this.loading = false;
        });
    },

    async getAssociationData() {
      Debug.log("function[getAssociationData]");
      this.loading = true;

      // 日冷倉協  TYpe = 0
      await Association.getAssociationList(0)
        .then(res => {
          if (res.data) {
            this.associationList = [];
            this.associationList.push({"associationId": "", "associationName": ""});
            res.data.forEach(a => {
              if (a.association_id != "000000") {
                this.associationList.push({"associationId": a.association_id, "associationName": a.association_name});
              }
            });

            Debug.log2("associationList->", this.associationList);
          }
        })
        .catch((err) => {
          Message.err(err, '情報を取得できませんでした。[協会データ]');
        })
        .finally(() => {
          this.loading = false;
        });

      // 冷事協会  TYpe = 1
      await Association.getAssociationList(1)
        .then(res => {
          if (res.data) {
            this.frozenAssociationList = [];
            this.frozenAssociationList.push({"associationId": "", "associationName": ""});
            res.data.forEach(a => {
              this.frozenAssociationList.push({"associationId": a.association_id, "associationName": a.association_name});
            });

            Debug.log2("frozenAssociationList->", this.frozenAssociationList);
          }
        })
        .catch((err) => {
          Message.err(err, '情報を取得できませんでした。[協会データ]');
        })
        .finally(() => {
          this.loading = false;
        });

    },

    memberChange() {
      this.check_main_office = this.mailCheckList[this.userData.member_no].check_main_office;
      this.check_member = this.mailCheckList[this.userData.member_no].check_member;

      Debug.log2("member_no", this.userData.member_no);
      Debug.log(this.mailCheckList);
    },

    checkChange() {
      this.mailCheckList[this.userData.member_no].check_main_office = this.check_main_office;
      this.mailCheckList[this.userData.member_no].check_member = this.check_member;
      this.mailCheckList[this.userData.member_no].data_save = 1;

      Debug.log2("member_no", this.userData.member_no);
      Debug.log(this.mailCheckList);
    },

    /** 保存 */
    async registData() {
      Debug.log('function[registData]');
      // Vuetify Validation
      if (!this.$refs.form.validate()) {
        return;
      }

      if (!confirm("更新します。よろしいですか？")) return;

      // 協会の場合は、member_no に協会ID を入れる
      if (this.association) {
        this.userData.member_no = this.association.association_id;
      }
      // if (this.company) {
      //   this.userData.member_no = this.company.company_id;
      // }

      this.loading = true;
      User.updateData(this.userData, this.mode)
        .then(res => {
          // サーバーサイドのバリデーション
          const validationMsg = res.data;
          if (validationMsg) {
            let message = "";
            if (Array.isArray(validationMsg)) {
              validationMsg.forEach(m => message += m + '\n')
            } else {
              message = validationMsg;
            }
            alert(message);

            this.loading = false;
            return;
          } else {
            alert("ユーザーデータを更新しました");
            this.close();
          }
        })
        .catch((err) => {
          alert(err)
          alert('情報を更新できませんでした。[registData]')
          console.error(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },

    /** 削除 */
    deleteData() {
      Debug.log("function[deleteData]");

      if (!confirm("削除します。よろしいですか？")) return;
      this.loading = true;
      User.deleteData(this.editEmail, this.userData)
        .then(res => {
          Debug.log(res);
          alert("ユーザーデータを削除しました");
          this.close();
        })
        .catch((err) => {
          alert('削除できませんでした。[deleteData]')
          console.error(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },

    close() {
      this.$emit("closeEvt");
    },
  },

  computed: {
    isDelete() {
      return this.mode == "delete";
    },

    isNew() {
      return this.mode == "new";
    },

    isMember() {
      return this.company != null;
    },

    to_address() {
      if (!this.userData.email) {
        return "";
      }
      return this.userData.to + '<' + this.userData.email + '>';
    },

    // パスワード用バリデーション
    password_rule() {
      const msg = '20文字以下, 半角英数字/ﾊｲﾌﾝ/ｱﾝﾀﾞｰﾊﾞｰが使えます'
      const required = v => !!v || "必須項目です"
      // const requiredIf = (predicate) => (v) => !predicate() || !!v || "必須項目です"
      const max_length= value => value.length <= 20 || "20文字以内で入力してください"
      const format_new = v => /^[\w-]{1,20}$/.test(v) || msg
      const format_edit = v => (!v || /^[\w-]{1,20}$/.test(v)) || msg
      // const rules = this.mode == 'new' ? [requiredIf(() => this.userData.login_flag == '1'), max_length, format_new]
      //                                  : [max_length, format_edit]
      const rules = (this.mode == 'new')
                        ? ( this.userData.login_flag == '1' ? [required, max_length, format_new] : [])
                        : [max_length, format_edit]
      return { rules }
    },
  },

}

</script>

<style scoped>
.disablecolor {
  background: #bbbbbb;
}

</style>
