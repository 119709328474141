<template>
  <v-container>
    <v-row >
      <v-col cols="2">
        <h3>ユーザ／連絡先 一覧</h3>
      </v-col>
      <v-col cols="5" class="flex-layout mt-3">
        <v-autocomplete label="会社"
              v-model="searchCondition.companyId"
              :items="companyList"
              item-text="company_name"
              item-value="company_id"
              dense
              :disabled="loading2"
              :loading="loading2"
              @change="selectCompany()"
              class="mr-5"
              :readonly="login_attribute == 1"
        ></v-autocomplete>
      <!-- </v-col>
      <v-col cols="3" class="mt-3"> -->
        <v-autocomplete label="協会名"
              v-model="searchCondition.associationId"
              :items="associationList"
              item-text="association_name"
              item-value="association_id"
              dense
              :disabled="loading2"
              :loading="loading3"
              @change="selectAssociation()"
              :readonly="login_attribute == 1"
        ></v-autocomplete>
      </v-col>
      <v-col cols="3" class="mt-3">
        <v-text-field label="検索"
            v-model="searchText"
            dense
            :disabled="loading"
            :loading="loading"
        ></v-text-field>
      </v-col>

      <v-col cols="2">
        <v-btn class="primary"
              v-on:click="show('new', '', null)"
              :disabled="loading || loading2 || loading3"
        >
          <v-icon left>mdi-plus-circle</v-icon>
          新規ユーザ登録
        </v-btn>
      </v-col>
    </v-row>

    <v-data-table
      :headers="headers"
      :footers="headers"
      :items="items"
      :loading="loading"
      dense
      :search="searchText"
      :mobile-breakpoint="0"
      :disable-pagination='true'
      :hide-default-footer='true'
      :fixed-header='true'
      height="calc(100vh - 250px)"
      multi-sort
      locale="ja-jp"
      loading-text="読込中"
      no-data-text="データがありません。"
      class="mt-5"
    >
      <template v-slot:item="{ item, index }">
        <tr :key="index" @dblclick="show('edit', item.email, item)">
          <td v-if="select_mode != 2">
            {{item.companyName}}
          </td>
          <td v-if="select_mode != 2">
            {{item.memberName}}
          </td>
          <td v-if="select_mode != 1">
            {{item.associationName}}
          </td>
          <td>
            {{item.name}}
          </td>
          <td>
            {{item.email}}
          </td>
          <td class="center">
            {{item.checkMainOffice == 1 ? '✓' : ''}}
          </td>
          <td class="center">
            {{item.checkMember == 1 ? '✓' : ''}}
          </td>
          <td class="center">
            {{item.attribute == 0 ? '○' : ''}}
          </td>
          <td class="center">
            {{item.attribute == 1 ? '○' : ''}}
          </td>
          <td class="center">
            {{item.attribute == 2 ? '○' : ''}}
          </td>
          <td class="center">
            {{item.login == 1 ? '●' : ''}}
          </td>
          <!-- <td>
            {{item.toAddress}}
          </td> -->

          <td class="min-width-200 px-0" >
            <v-btn small class="mx-1 btn-font primary" v-on:click="show('edit', item.email, item)" :height="20" :width="50" :disabled="loading">
              <v-icon :small="true">mdi-border-color</v-icon>編集
            </v-btn>
            <v-btn small class="mx-1 btn-font secondary" v-on:click="show('delete', item.email, item)" :height="20" :width="50" :disabled="loading">
              <v-icon :small="true">mdi-delete</v-icon>削除
            </v-btn>
          </td>
        </tr>
      </template>
    </v-data-table>

    <!------------------------------------------------------------>
    <!-- 事業所ユーザ modal -->
    <modal name="modal-dialog" :draggable="false" :clickToClose="false" :scrollable="true" height="auto" width="50%" id="vm--modal">
      <div class="modal-body">
        <UserEdit
            :mode="mode"
            :editEmail="editEmail"
            :company="company"
            :association="association"
            :memberNo="memberNo"
            :attribute="attribute"
            v-on:closeEvt="hide"
        />
      </div>
    </modal>

  </v-container>

</template>

<script>
import Muser from '../model/m_user'
import Company from '../model/m_company'
import Association from '../model/m_association'
import UserEdit from "./UserEdit.vue";

import Const from '../mixins/const';
import Message from '../Lib/Message';
import Debug from '../Lib/Debug';


export default {
  components: {
    UserEdit,
  },

  mixins: [Const],

  data: () => ({
    loading: false,
    loading2: false,
    loading3: false,

    login_attribute: 0,

    headers: [],
    headers0: [
      { text: '会社名', align: 'left', sortable: true, value: 'companyName', width: "180px" },
      { text: '事業所名', align: 'left', sortable: true, value: 'memberName', width: "200px" },
    ],
    headers1: [
      { text: '都道府県協会名', align: 'left', sortable: true, value: 'associationName', width: "180px" },
    ],
    headers2: [
      { text: '名前', align: 'left', sortable: true, value: 'name', width: "200px" },
      { text: 'メールアドレス', align: 'left', sortable: true, value: 'email', width: "300px" },
      { text: '本社　　ﾒｰﾙ', align: 'center', sortable: true, value: 'check_main_office', width: "70px" },
      { text: '事業所　ﾒｰﾙ', align: 'center', sortable: true, value: 'check_member', width: "70px" },
      { text: '事業所', align: 'center', sortable: true, value: 'isMember', width: "72px" },
      { text: '協会　', align: 'center', sortable: true, value: 'isAssociation', width: "70px" },
      { text: '日冷倉', align: 'center', sortable: true, value: 'isNichireisou', width: "70px" },
      { text: 'ﾛｸﾞｲﾝ　', align: 'center', sortable: true, value: 'isLogin', width: "80px" },
      // { text: '宛先', align: 'left', sortable: true, value: 'toAddress', width: "400px" },
      { text: '', align: 'center', sortable: false, value: 'action', width: "300px" },
      { text: '', align: 'center', sortable: false}
    ],

    items: [],

    searchCondition: {
      screenType: 'UserList',
      companyId: "",
      associationId: "",
    },
    searchText: '',
    company: null,
    association: null,
    memberNo: null,
    attribute: 0,
    companyList: [],
    associationList: [],

    select_mode: 0,

    // edit用
    mode: '',
    editEmail: '',
  }),

  methods: {
    async loadList() {
      Debug.log('function[loadList]');

      const select_company = (this.searchCondition.companyId)
                                  && (this.searchCondition.companyId.length > 0);
      const select_association = (this.searchCondition.associationId)
                                  && (this.searchCondition.associationId.length > 0);
      if (select_company) {
        this.select_mode = 1;
      } else if (select_association) {
        this.select_mode = 2;
      } else {
        this.select_mode = 0;
      }

      // 検索条件で ヘッダを可変させる
      this.headers = [];
      if (this.select_mode == 1) {
        this.headers0.forEach(el => this.headers.push(el));
      } else if (this.select_mode == 2) {
        this.headers1.forEach(el => this.headers.push(el));
      } else {
        this.headers0.forEach(el => this.headers.push(el));
        this.headers1.forEach(el => this.headers.push(el));
      }
      this.headers2.forEach(el => this.headers.push(el));

      Debug.log('company_id:' + this.searchCondition.companyId.length)
      Debug.log('association_id:' + this.searchCondition.associationId.length)

      this.loading = true
      this.items = [];

      // 会員のみに変更
      this.searchCondition["listType"] = 0;
      // ユーザ情報を取得
      Muser.search(this.searchCondition)
        .then(res => {
          if (res && res.data) {
            this.items = res.data;

            // デフォルトのデータソート
            // attribute / 会社名 / 事業所名 / 名前
            this.items.sort((a, b) => {
              if (a.attribute < b.attribute) return -1;
              else if (a.attribute > b.attribute) return 1;

              var result = a.companyName.localeCompare(b.companyName);
              if (result != 0) return result;

              result = a.memberName.localeCompare(b.memberName);
              if (result != 0) return result;

              result = a.name.localeCompare(b.name);
              return result;
            });

            Debug.log2("userList->", this.items);

            // 検索条件を保存
            this.$store.commit('setStoreSearchCondition', this.searchCondition);

          }
        })
        .catch((err) => {
          Message.err(err, '情報を取得できませんでした。[ユーザデータ]');
        })
        .finally(() => {
          this.loading = false;
        });
    },

    async getCompanyList() {
      Debug.log("function[getCompanyList]");
      this.loading2 = true;

      // DBから会員情報取得
      Company.getDataList()
        .then(res => {
          if (res.data) {
            // this.companyList = res.data;
            this.companyList = [ { company_name: "", company_id: ""} ];
            res.data.forEach(element => {
              this.companyList.push(element);
            });

            if (this.searchCondition.companyId) {
              this.company = this.companyList.find(cp => cp.company_id == this.searchCondition.companyId);
            }

            Debug.log2("companyList->", this.companyList);
          }
        })
        .catch((err) => {
          Message.err(err, "会社リストを取得できませんでした");
        })
        .finally(() => {
          this.loading2 = false;
        });
    },

    async getAssociationList() {
      Debug.log("function[getAssociationList]");
      this.loading3 = true;

      // DBから協会情報取得
      Association.getDataList()
        .then(res => {
          if (res.data) {
            // this.associationList = res.data;
            this.associationList = [ { association_name: "", association_id: ""} ];
            res.data.forEach(element => {
              if (element.association_id != "000000") {
                this.associationList.push(element);
              }
            });
            this.associationList.push({ association_name: "日本冷蔵倉庫協会", association_id: "000000"});

            if (this.searchCondition.associationId) {
              this.association = this.associationList.find(as => as.association_id == this.searchCondition.associationId);
            }

            Debug.log2("associationList->", this.associationList);
          }
        })
        .catch((err) => {
          Message.err(err, "協会を取得できませんでした");
        })
        .finally(() => {
          this.loading3 = false;
        });
    },

    // ------------------------------------------------------------------------
    // select イベント
    selectCompany() {
      this.searchCondition.associationId = "";
      this.company = this.companyList.find(cp => cp.company_id == this.searchCondition.companyId);
      this.association = null;

      Debug.log2("company ->", this.company)

      this.loadList();
    },

    selectAssociation() {
      this.searchCondition.companyId = "";
      this.association = this.associationList.find(as => as.association_id == this.searchCondition.associationId);
      this.company = null;

      this.loadList();
    },

    // ------------------------------------------------------------------------
    // モーダル表示用
    show (mode, editEmail, item) {
      Debug.log('function[show]');
      Debug.log2(item);
      this.mode = mode;
      this.editEmail = editEmail;

      if (this.mode == 'new' && this.select_mode == 0) {
        alert("新規登録する「会社」または「協会」を選択してください。");
        return;
      }

      if (((this.mode == 'edit') || (this.mode == 'delete')) && (item != null)) {
        if (item.companyId) {
          this.company = this.companyList.find(cp => cp.company_id == item.companyId);
          this.memberNo = item.memberNo;
          // 協会データは初期化
          this.association = null;

          if (!this.company) {
             this.company = { company_name: item.companyName, company_id: item.companyId};
          }

        } else if (item.associationId) {
          this.association = this.associationList.find(as => as.association_id == item.associationId);
          // 協会データは初期化
          this.company = null;
          this.memberNo = null;
        }
        this.attribute = item.attribute;
      }

      Debug.log2('edit mode : ', mode);
      Debug.log2('disp email : ', editEmail);
      Debug.log2("item :", item);
      Debug.log2("attribute :", this.attribute);

      this.$modal.show('modal-dialog');
    },

    hide : function () {
      Debug.log('function[hide]');
      this.$modal.hide('modal-dialog');

      // TABLEを読み直す
      this.loadList();
    },

  },

  created: function() {
  },

  mounted : function() {
    this.login_attribute = this.$store.getters.getAttribute;  // 属性

    // GETパラメータを取得
    if (this.$route.query.company_id) {
      this.searchCondition.companyId = this.$route.query.company_id;
    } else if (this.$route.query.association_id) {
      this.searchCondition.associationId = this.$route.query.association_id;
    }

    // 検索条件 を読み込む
    const storeSearchCondition = this.$store.getters.getStoreSearchCondition;
    if (storeSearchCondition.screenType == 'UserList') {
      this.searchCondition = storeSearchCondition;
    }
    Debug.log2("searchCondition", this.searchCondition);


    this.getCompanyList();
    this.getAssociationList();

    this.loadList();
},

}
</script>


<style scoped>
.v-data-table table {
  width: initial !important;
}

.v-data-table table th {
  font-size: 12pt;
}

.v-data-table table td {
  font-size: 11pt;
}

/* modal dialog */
.modal-header, .modal-body {
  padding: 5px 25px;
}
.modal-header {
  border-bottom: 1px solid #ddd;
}

.vm--modal {
  min-width: 400px;
  max-width: 600px;
}

.btn-font {
  color: white;
}

.v-text-field {
  padding-top: 2px;
  padding-bottom: 2px;
  margin-top: 4px;
  margin-bottom: 4px;
}

th {
  white-space: pre-line;
}

.Footer {
  position: fixed; /* フッターを固定する */
  bottom: 0; /* 上部から配置の基準位置を決める */
  left: 0; /* 左から配置の基準位置を決める */
  width: 100%; /* フッターの横幅を指定する */
  height: 35px; /* フッターの高さを指定する */
  padding:10px; /* フッター内側の余白を指定する(上下左右) */
  color: #000000; /* フッターのフォントの色を指定する */
  border-top: 3px solid #ff9696; /* フッターの境界線（上部）を指定する */
}

</style>
