import Axios from "axios";
import token from "./setToken";

export default {

  async getDataList(listType = 0) {
    try {
      // await token.checkToken();
      // const res = await Axios.get("/api/Company", token.setTokenToHeader());
      const res = await Axios.get("/api/Company?type=" + listType, token.setTokenToHeader());
      return res;
    } catch (error) {
      console.error("会社一覧を取得できません[getDataList]");
      throw error;
    }
  },


  async getData(id) {
    try {
      // await token.checkToken();
      const res = await Axios.get("/api/Company/" + id, token.setTokenToHeader());
      return res;
    } catch (error) {
      console.error("会社データを取得できません[getDataList]");
      throw error;
    }
  },


  async setCompanyData(companyData, mode) {
    let params = new URLSearchParams();
    params.append('companyData', JSON.stringify(companyData));
    params.append('mode', mode);

    try {
      // await token.checkToken();
      const res = await Axios.post('/api/Company', params, token.setTokenToHeader());
      return res;

    } catch (error) {
      console.error("会社データを設定できません");
      throw error;
    }
  },


  async delCompanyData(id) {
    try {
      const res = await Axios.delete('/api/Company/' + id, token.setTokenToHeader());
      return res;

    } catch (error) {
      console.error("会社データを削除できません");
      throw error;
    }
  },


  /**
   * 新規会社ID を取得
   */
    async getNewId() {
      try {
        // await token.checkToken();
        const res = await Axios.get('/api/CompanyId', token.setTokenToHeader());
        return res;
      } catch (error) {
        console.error("会員情報を取得できません[getNewNo]");
        throw error;
      }
    },
  

};
